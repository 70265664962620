export const BASE_URL = "https://dev-server-3.analyzerei.io/api/v2/";
export const UPLOAD_URL = "https://dev-server-3.analyzerei.io/uploads/";
export const API_URL = "https://dev-server-3.analyzerei.io/api/v2/";
export const IMAGE_DIR = "https://dev-server-3.analyzerei.io/assets/img/";
export const DOWNLOAD_FILE_URL = "https://dev-server-3.analyzerei.io/public/excelsfiles/";

// strip keys
export const STRIPE_MODE = false;
export const STRIPE_TEST_KEY = "pk_test_4gxBF9OjWkDBkpDGt5f3wjmX00k1ywEKH0";
export const STRIPE_LIVE_KEY = "pk_live_CqT98QPEovGhvBUoyJiesYYb00l30DQq2Z";

export const SOCKET_URL = "https://sk23etsz1.analyzerei.io";